<template>
    <div>
        <v-container fluid class="pt-0 mt-5">
            <v-row no-gutters>
                <v-col cols="12">
                    <v-alert :dismissible="false" class="light-green accent-4 white--text" dark border="left" icon="fas fa-calendar-alt">
                        <v-row no-gutters class="align-center mt-n1 mb-n1">
                        	<v-col cols="10" class="pa-0">
								<span>HA Stats</span>
                        	</v-col>
                        	<v-col cols="2" class="pa-0">
                        		<v-menu dense v-model="endDateMenu" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
						        	<template v-slot:activator="{ on, attrs }">
						        		<v-text-field dense v-model="computedDateFormatted" prepend-icon="fas fa-calendar-alt" hide-details="false" readonly v-bind="attrs" v-on="on"></v-text-field>
						        	</template>
						          	<v-date-picker dense v-model="endDate" no-title @input="endDateMenu = false"></v-date-picker>
						        </v-menu>
                        	</v-col>
                        </v-row>
                    </v-alert>
                </v-col>
                <v-col cols="12">
                	<v-tabs v-model="currencyIndex" background-color="grey lighten-2" show-arrows next-icon="mdi-menu-right" prev-icon="mdi-menu-left">
	                    <v-tab v-for="(currency, index) in currencies" :key="index">
	                        <span class="text-none">{{ currency }}</span>
	                    </v-tab>
	                </v-tabs>
                </v-col>
				<v-col cols="12">
					<v-row dense v-if="loading"> 
                        <v-col cols="12">
                            <v-skeleton-loader dense type="table-row-divider@15" class="mx-auto"></v-skeleton-loader>
                        </v-col>
                    </v-row>
					<!-- 表头 -->
					<v-data-table dense :headers="headers" :items="statsList" disable-sort hide-default-footer v-if="!loading">
						<!-- 表数据 -->
						<template v-slot:body>
							<tbody>
								<tr  v-if="statsList.length == 0">
									<td colspan="9" class="text-center">No Data Available</td>
								</tr>
								<tr  v-if="statsList.length > 0" v-for="(stats, index) in statsList" :key="index">
									<td>{{ stats.date }}</td>
									<td class="text-right">{{ common.formatNum(stats.atr3_k) }}</td>
									<td class="text-right">{{ common.formatNum(stats.atr14_k) }}</td>
									<td class="text-right">{{ common.formatNum(stats.atr3_ha) }}</td>
									<td class="text-right">{{ common.formatNum(stats.atr14_ha) }}</td>
									<td class="text-right">{{ common.formatNum(stats.atr14_ha_avg100) }}</td>
									<td class="text-right">{{ common.formatNum(stats.ema80_ha) }}</td>
									<td class="text-right">{{ common.formatNum(stats.ema140_ha) }}</td>
									<td class="text-right">{{ common.formatNum(stats.ema200_ha) }}</td>
									<td class="text-right">{{ common.formatNum(stats.marketTypes) }}</td>
									<!-- *********************************MaxOpen********************************* -->
									<td class="text-right px-2">
										<span v-if="stats.maxOpen != null">
											{{ common.formatNum(stats.maxOpen.longMaxOpen) }}
										</span>
										<span v-else>--</span>
									</td>
									<td class="text-right px-2">
										<span v-if="stats.maxOpen != null">
											{{ common.formatNum(stats.maxOpen.shortMaxOpen) }}
										</span>
										<span v-else>--</span>
									</td>
									<!-- *********************************OKEx Futures********************************* -->
									<td class="text-right px-2">
										<span v-if="stats.okexLiquidationPrice != null">
											{{ common.formatNum(stats.okexLiquidationPrice.longLiquidationPrice) }}
										</span>
										<span v-else>--</span>
									</td>
									<td class="text-right px-2">
										<span v-if="stats.okexLiquidationPrice != null">
											{{ common.formatNum(stats.okexLiquidationPrice.shortLiquidationPrice) }}
										</span>
										<span v-else>--</span>
									</td>
									<!-- *********************************Kraken Futures********************************* -->
									<td class="text-right px-2">
										<span v-if="stats.krakenLiquidationPrice != null">
											{{ common.formatNum((stats.krakenLiquidationPrice.longLiquidationPrice).toFixed(futuresDecimalNumbers['PI_' + currencies[currencyIndex] + 'USD'])) }}
										</span>
										<span v-else>--</span>
									</td>
									<td class="text-right px-2">
										<span v-if="stats.krakenLiquidationPrice != null">
											{{ common.formatNum((stats.krakenLiquidationPrice.shortLiquidationPrice).toFixed(futuresDecimalNumbers['PI_' + currencies[currencyIndex] + 'USD'])) }}
										</span>
										<span v-else>--</span>
									</td>
									<!-- *********************************FTX USDT Swap********************************* -->
									<td class="text-right px-2">
										<span v-if="stats.ftxLiquidationPrice != null">
											{{ common.formatNum((stats.ftxLiquidationPrice.longLiquidationPrice).toFixed(getSwapUsdtDecimalPlace('ftx', currencies[currencyIndex] + '-USDT-SWAP'))) }}
										</span>
										<span v-else>--</span>
									</td>
									<td class="text-right px-2">
										<span v-if="stats.ftxLiquidationPrice != null">
											{{ common.formatNum((stats.ftxLiquidationPrice.shortLiquidationPrice).toFixed(getSwapUsdtDecimalPlace('ftx', currencies[currencyIndex] + '-USDT-SWAP'))) }}
										</span>
										<span v-else>--</span>
									</td>
									<!-- *********************************Candle********************************* -->
									<td class="text-right px-2" v-if="stats.candle != null">{{ common.formatNum(stats.candle.open) }}</td>
									<td class="text-right px-2" v-if="stats.candle != null">{{ common.formatNum(stats.candle.high) }}</td>
									<td class="text-right px-2" v-if="stats.candle != null">{{ common.formatNum(stats.candle.low) }}</td>
									<td class="text-right px-2" v-if="stats.candle != null">{{ common.formatNum(stats.candle.close) }}</td>
								</tr>
							</tbody>
						</template>
					</v-data-table>
					<v-divider v-if="!loading"></v-divider>
				</v-col>
				<v-col cols="6" class="mt-10">
					<v-data-table dense :headers="highAndLowHeaders" :items="highAndLowList" hide-default-footer v-if="!loading">
						<!-- 表数据 -->
						<template v-slot:body>
							<tbody>
								<tr v-if="highAndLowList.length == 0">
									<td colspan="9" class="text-center">No Data Available</td>
								</tr>
								<tr v-if="highAndLowList.length > 0" v-for="(stats, index) in highAndLowList" :key="index">
									<td>{{ stats.name }}</td>
									<td>{{ stats.valueDate }}</td>
									<td class="text-right">{{ common.formatNum(stats.value) }}</td>
									<td class="text-right">{{ common.formatNum(stats.ema80_ha) }}</td>
									<td class="text-right">{{ common.formatNum(stats.atr14_ha) }}</td>
								</tr>
							</tbody>
						</template>
					</v-data-table>
					<v-divider v-if="!loading"></v-divider>
				</v-col>
				
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import Vue from 'vue';
    import config from '../../config';
    import { mapGetters } from "vuex";
    export default {
        data() {
            return {
                headers: [
                	{ text: 'Date', sortable: false },
                	{ text: 'ATR3-K', align: 'end', sortable: false },
                    { text: 'ATR14-K', align: 'end', sortable: false },
					{ text: 'ATR3-HA', align: 'end', sortable: false },
					{ text: 'ATR14-HA', align: 'end', sortable: false },
					{ text: 'ATR14-HA Avg100', align: 'end', sortable: false },
					{ text: 'EMA80-HA', align: 'end', sortable: false },
					{ text: 'EMA140-HA', align: 'end', sortable: false },
					{ text: 'EMA200-HA', align: 'end', sortable: false },
					{ text: 'Market Types', align: 'end', sortable: false },
					{ text: 'MOL', align: 'end', class: 'px-2' },
					{ text: 'MOS', align: 'end', class: 'px-2' },
					{ text: '(O)LPL', align: 'end', class: 'px-2' },
					{ text: '(O)LPS', align: 'end', class: 'px-2' },
					{ text: '(K)LPL', align: 'end', class: 'px-2' },
					{ text: '(K)LPS', align: 'end', class: 'px-2' },
					{ text: '(F)LPL', align: 'end', class: 'px-2' },
					{ text: '(F)LPS', align: 'end', class: 'px-2' },
					{ text: 'O', align: 'end', class: 'px-2' },
					{ text: 'H', align: 'end', class: 'px-2' },
					{ text: 'L', align: 'end', class: 'px-2' },
					{ text: 'C', align: 'end', class: 'px-2' }
                ],
                highAndLowHeaders: [
                	{ text: 'Name', sortable: false },
                	{ text: 'Date', sortable: false },
                	{ text: 'Value', align: 'end', sortable: false },
					{ text: 'EMA80-HA', align: 'end', sortable: false },
					{ text: 'ATR14-HA', align: 'end', sortable: false },
                ],
                currencyIndex: 0,
                project: null,
                currencies: [],
                statsList: [],
                highAndLowList:[],
				loading:false,
				endDateMenu: false,
				endDate: new Date(new Date().getTime() - 86400000).toISOString().substr(0, 10),
            }
        },
        computed: {
            ...mapGetters(['user', 'futuresDecimalNumbers', 'swapUsdtSymbolInfos']),
            computedDateFormatted () {
		        return this.formatDate(this.endDate)
		    },
        },
        watch: {
            currencyIndex(val){
            	this.getStats();
            },
            endDate(val){
            	this.getStats();
            }
        },
        mounted() {
            if (!this.user.token) {
                this.$router.push({ name: 'login' })
            };
            this.getAllIndicatorStatsCurrencies();
        },
        created() {
           
        },
        methods: {
        	// 查询项目
        	getAllIndicatorStatsCurrencies(){
		       	let api = config.baseUrl + '/symbol/public/currencies/indicator/stats';
		       	this.$http.get(api).then(response => {
	               if (response.data.code == 200) {
	                   this.currencies = response.data.result;
	                   this.getStats();
	               }
	           })
        	},
        	// 获取数据
		   	getStats() {
			   this.loading = true;
			   this.statsList = [];
			   let api = config.baseUrl + '/indicator/stats/public/stats/summary?currency=' + this.currencies[this.currencyIndex] + '&endDate=' + this.endDate;
		       this.$http.get(api).then(response => {
		       		this.loading = false;
	               	if (response.data.code == 200) {
	                   this.statsList = response.data.result.data;
	                   this.highAndLowList = response.data.result.highAndLow;
	               	}
	           })
		   	},
		   	formatDate (date) {
		        if (!date) return null
		        const [year, month, day] = date.split('-')
		        return `${day}/${month}/${year}`
		    },
			// 获取小数位
			getSwapUsdtDecimalPlace(exchange, symbol){
				if (exchange == null || symbol == null) {
				return 0;
				}
				let currentExchangeSwapUsdtSymbolInfos = this.swapUsdtSymbolInfos[exchange.toLowerCase()];
				for(let i = 0, len = currentExchangeSwapUsdtSymbolInfos.length; i < len; i++){
				if (currentExchangeSwapUsdtSymbolInfos[i].symbol == symbol) {
					return currentExchangeSwapUsdtSymbolInfos[i].priceDecimalPlace;
				}
				}
				return 0;
			},
        }
    }
</script>

<style>

</style>